import { getEnums } from '@/utils/utils'

//企业版
export const applyColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '申请编码',
    dataIndex: 'applicationCode',
    key: 'applicationCode',
    align: 'left',
    // ellipsis: true,
    width: 200,
    slots: { customRender: 'applicationCode' }
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    // ellipsis: true,
    width: 270,
    slots: { customRender: 'fileName' }
  },

  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    slots: { customRender: 'staffName' },
    width: 106
  },
  {
    title: '所在部门',
    dataIndex: 'departmentName',
    key: 'departmentName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'departmentName' },
    width: 190
  },
  {
    title: '申请印章',
    dataIndex: 'sealNames',
    key: 'sealNames',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealNames' },
    width: 300
  },
  {
    title: '申请次数',
    dataIndex: 'applySums',
    key: 'applySums',
    align: 'left',
    slots: { customRender: 'applySums' },
    width: 111
  },
  {
    title: '业务类型',
    dataIndex: 'processType',
    key: 'processType',
    align: 'left',
    width: 111,
    customRender: ({ text }) => getEnums('PROCESS_TYPE', text)?.desc || '--'
  },
  {
    title: '流程名称',
    dataIndex: 'processName',
    key: 'processName',
    align: 'left',
    // ellipsis: true,
    width: 219,
    slots: { customRender: 'processName' }
  },
  {
    title: '提交申请时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'left',
    sorter: true,
    width: 186
  },
  {
    title: '申请状态',
    dataIndex: 'examineStatus',
    key: 'examineStatus',
    align: 'left',
    slots: { customRender: 'examineStatus' },
    width: 112
  },
  {
    title: '流程状态',
    dataIndex: 'sealStatus',
    key: 'sealStatus',
    align: 'left',
    slots: { customRender: 'sealStatus' },
    width: 106
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 130
  }
]
//政务版
export const applyColumn = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    ellipsis: true,
    width: 150
  },
  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'staffName' },
    width: 100
  },
  {
    title: '行政区域',
    dataIndex: 'departmentName',
    key: 'departmentName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'departmentName' },
    width: 100
  },
  {
    title: '申请印章',
    dataIndex: 'sealNames',
    key: 'sealNames',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealNames' },
    width: 100
  },
  {
    title: '申请次数',
    dataIndex: 'applySums',
    key: 'applySums',
    align: 'left',
    width: 100
  },
  {
    title: '预计用印日期',
    dataIndex: 'sealTime',
    key: 'sealTime',
    align: 'left',
    slots: { customRender: 'sealTime' },
    width: 150
  },
  {
    title: '提交申请时间',
    dataIndex: 'applyTime',
    key: 'applyTime',
    align: 'left',
    sorter: true,
    width: 200
  },
  {
    title: '自助申请',
    dataIndex: 'selfUserName',
    key: 'selfUserName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'selfUserName' },
    width: 100
  },
  {
    title: '审批状态',
    dataIndex: 'examineStatus',
    key: 'examineStatus',
    align: 'left',
    slots: { customRender: 'examineStatus' },
    width: 100
  },
  // {
  //   title: '用印状态',
  //   dataIndex: 'sealStatus',
  //   key: 'sealStatus',
  //   align: 'left',
  //   slots: { customRender: 'sealStatus' },
  //   width: 100
  // },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    // align: 'center',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 100
  }
]
